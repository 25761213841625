@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.CoolRedirectContainer {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  align-content: center;

  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .checkListItem{
    display: flex;
    justify-content: start;
    font-size: 36px;
    margin: 15px auto;
  }

  .checkListItem .textItem {
    margin-left: 7px;
  }

  .unvisible {
      visibility: hidden;
  }

  .visible {
      visibility: visible;
  }
}