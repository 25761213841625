@import "~bootstrap/scss/bootstrap";


body, .App header h1 a {
  color: #EFE9F4;
}

.App header {
  display: flex;
  align-items: flex-end;
  flex: 1;
  flex-direction: row;
  margin: 20px min(70px,10vh) 50px;
  height: 70px;
  max-height: 150px;
}

.App header #colorSquare {
  height: 52px;
  width: 52px;
  border-radius: 4px;
  background-color: rgb(203,77,140);
}

.App header h1 {
  margin: 0 0 10px 10px;
  padding: 0;
}

.App header h1 a {
  text-decoration: none;
  font-size: 0.6em;
  line-height: min(0.9em, 3vw);
  margin: 0;
  white-space:nowrap;
}

.App header .examples {
  margin-left: 15px;
  margin-right: auto;
  margin-bottom: 15px;
  font-size: 1em;
  
  a {
    color: #2835F6;
  }
}

.App header .walletButton {
  margin-left: 20px;
  margin-bottom: 4px;
}

.App {
  text-align: center;
}

.loadingWrapper {
  font-size: 10vh;
}

.loadingEthereum {
  animation: spinner 1.5s linear infinite;
}

.prettyBackground {
  background-image: url("../public/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

footer {
  font-size: 12px;
  margin: 30px 0 5px;
  text-align: center;

  &, a {
    color: gray;
  }
}