.exampleList {
   max-width: 1200px;
   margin: 0 auto;
   padding: 30px 20px 50px;
}

.exampleList, .exampleList ul li {
   text-align: left;
}   

.exampleList a {
   color:white;
}